jQuery(document).ready(function($) {

    // clear and restore form input values
    var el = $('input[type=text], input[type=email], textarea, input.header-search');
    el.focus(function(e) {
        if (e.target.value === e.target.defaultValue)
            e.target.value = '';
    });
    el.blur(function(e) {
        if (e.target.value === '')
            e.target.value = e.target.defaultValue;
    });

    $('.album ul').magnificPopup({
        type: 'image',
        delegate: 'li a'
    });

    $('body').on('click', '.alert .fa-close', function () {
        $('.alert').addClass('closed');
    });

    $('#calculatorTabs').easyResponsiveTabs({
        type: 'accordion'
    });
});
